// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useDispatch, useSelector } from "react-redux";
import logo from "./dachvest-logo.png";
import "./sidebar.scss";
import {
  DashboardCustomizeOutlined,
  HistoryEduOutlined,
  LockClockOutlined,
  Timer,
  WalletOutlined,
} from "@mui/icons-material";
import { Timer10Outlined, TimerOffOutlined } from "@material-ui/icons";
const Item = ({ title, to, icon, selected, setSelected, setCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setCollapsed(true);
        setSelected(title);
      }}
      icon={icon}
      component={<Link to={to} />}
      className="sidebarItem"
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const [collapsed, setCollapsed] = useState(true);
  const sidebarToggle = useSelector((state) => state.sidebar.sidebar);
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const isUserAdminOrAgent =
    user?.role === "admin" || user?.role === "retention";
  const isUserAdmin = user?.role === "admin";
  const isUserConversion = user?.role === "conversion";
  const isUserCrmManager = user?.role === "crm_manager";
  const isUserHeadOfConversion = user?.role === "head_of_conversion";
  return (
    <Box
      sx={{
        // position: "absolute",
        display: "flex",
        // height: "120vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "black !important",
        },
        "& .ps-menu-button:hover": {
          backgroundColor: "#3DA58A !important",
        },
        "& .ps-menu-button.active": {
          backgroundColor: "#3DA58A !important",
        },
        ".& .ps-menu-icon": {
          width: "30px !important",
          height: "30px !important",
        },
        "& .menu-item.active": {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: "black !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[400]}
        image={sidebarImage}
        collapsed={collapsed}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              <MenuOutlinedIcon
                style={{ color: "white", width: "100%", height: "80%" }}
                onClick={() => {
                  setCollapsed((prev) => !prev);
                  window.localStorage.setItem("sidebar", `${collapsed}`);
                }}
              />
            }
            style={{
              margin: "10px 0 20px 0",
              color: "white",
            }}
          ></MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <img src={logo} height="190" width="240" />
                <Typography
                  variant="h5"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                  style={{ margin: "1rem auto", color: "#3DA58A" }}
                >
                  {user?.firstName + " " + user?.lastName}
                </Typography>
              </Box>
            </Box>
          )}
          <Box>
            {isUserAdminOrAgent && (
              <Item
                title="Dashboard"
                to="/admin/dashboard"
                icon={
                  <HomeOutlinedIcon
                    style={{ color: "white", width: "80%", height: "80%" }}
                  />
                }
                selected={selected}
                setSelected={setSelected}
                setCollapsed={setCollapsed}
              />
            )}
            {(isUserAdminOrAgent ||
              isUserCrmManager ||
              isUserConversion ||
              isUserHeadOfConversion) && (
              <Item
                title="Manage Users"
                to="/admin/users"
                icon={
                  <PeopleOutlinedIcon
                    style={{ color: "white", width: "80%", height: "80%" }}
                  />
                }
                selected={selected}
                setCollapsed={setCollapsed}
                setSelected={setSelected}
              />
            )}
            {isUserAdmin && (
              <Item
                title="Manage Affiliates"
                to="/admin/affiliate"
                icon={
                  <TimelineOutlinedIcon
                    style={{ color: "white", width: "80%", height: "80%" }}
                  />
                }
                selected={selected}
                setCollapsed={setCollapsed}
                setSelected={setSelected}
              />
            )}
            {/* {!isUserCrmManager && !isUserConversion && (
              <Item
                title="Score Board"
                to="/admin/board"
                icon={
                  <DashboardCustomizeOutlined
                    style={{ color: "white", width: "80%", height: "80%" }}
                  />
                }
                selected={selected}
                setCollapsed={setCollapsed}
                setSelected={setSelected}
              />
            )} */}

            <Item
              title="Trade Page"
              to="/trade"
              icon={
                <BarChartOutlinedIcon
                  style={{ color: "white", width: "80%", height: "80%" }}
                />
              }
              selected={selected}
              setCollapsed={setCollapsed}
              setSelected={setSelected}
            />
            <Item
              title="Landing Page"
              to="/"
              icon={
                <MapOutlinedIcon
                  style={{ color: "white", width: "80%", height: "80%" }}
                />
              }
              selected={selected}
              setCollapsed={setCollapsed}
              setSelected={setSelected}
            />
            <Item
              title="Wallet"
              to="/wallet"
              icon={
                <WalletOutlined
                  style={{ color: "white", width: "80%", height: "80%" }}
                />
              }
              selected={selected}
              setCollapsed={setCollapsed}
              setSelected={setSelected}
            />
            <Item
              title="Trade History"
              to="/history"
              icon={
                <Timer
                  style={{ color: "white", width: "80%", height: "80%" }}
                />
              }
              selected={selected}
              setCollapsed={setCollapsed}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
